<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for="">Nombre</label>
      <input type="text" class="form-control" v-model="reg.name" required>
      <app-small-form-errors :errors="errors.name"></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for="">Dirección</label>
      <input type="text" class="form-control" v-model="reg.address" required>
      <app-small-form-errors :errors="errors.address"></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for="">Entidad</label>
      <SelectEntity v-model="reg.entity_id"></SelectEntity>
      <app-small-form-errors :errors="errors.entity_id"></app-small-form-errors>
    </div>
    <app-button-submit :disabled="errors"></app-button-submit>
  </form>
</template>

<script>
import SelectEntity from '../../admin-module/entitys/Select'
import * as validate from 'validate.js'
import { LogisticService } from '../service';

const formRules = {
  name: {presence: {message: 'Requerido'}},
  address: {presence: {message: 'Requerido'}},
  entity_id: {presence: {message: 'Requerido'}},
}

export default {
  components: {
    SelectEntity
  },
  data: () => ({
    reg: {}
  }),
  computed: {
    errors() {
      let err = validate(this.reg, formRules, { fullMessages: false });
      return err ? err : false;
    }
  },
  methods: {
    loadReg(reg) {
      this.reg = JSON.parse(JSON.stringify(reg));
    },
    reset() {
      this.reg = {}
    },
    save() {
      if(!this.errors){
        LogisticService.saveStore(this.reg).then(
        res => this.$emit('submitted', res)
      )
      }
    }
  }
}
</script>

<style>

</style>
