<template>
  <div>
    <h4>ALMACENES</h4>
    <app-table-registers
      :getList="getList"
      ref="tr"
      @btnNew="$refs.dForm.show(); $refs.oForm.reset();"
    >
      <template slot="table">
        <thead></thead>
        <tbody>
          <tr v-for="(l, index) in list.data" :key="index">
            <td>{{ l.id }}</td>
            <td>{{ l.name }}</td>
            <td>{{ l.address }}</td>
            <td>{{ l.entity_name }}</td>
            <td>
              <div class="btn-group">
                <button class="btn btn-light" @click="$refs.dForm.show(); $refs.oForm.loadReg(l)">
                  <i class="fa fa-edit"></i>
                </button>
                <button class="btn btn-light" @click="$refs.dDel.val = l.id; $refs.dDel.show();">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>

    <app-modal-basic ref="dForm">
      <oForm ref="oForm" @submitted="$refs.tr.gl(); $refs.dForm.hide()"></oForm>
    </app-modal-basic>

    <app-modal-yn ref="dDel" @yes="deleteItem($refs.dDel.val)   " @no="$refs.dDel.hide()">
      <p v-if="$refs.dDel && $refs.dDel.val">
        Eliminar el registro
        <b>{{ $refs.dDel.val }}</b>
      </p>
    </app-modal-yn>
  </div>
</template>

<script>
import { LogisticService } from "../service";
import oForm from "./Form";

export default {
  components: {
    oForm
  },
  data: () => ({
    list: {}
  }),
  methods: {
    getList(params) {
      return new Promise(rsv => {
        LogisticService.getStores(params).then(res => {
          this.list = res;
          rsv(res);
        });
      });
    },
    deleteItem(i) {
      LogisticService.deleteStore(i).then(() => {
        this.$refs.tr.gl();
        this.$refs.dDel.hide();
      });
    }
  }
};
</script>

<style>
</style>
